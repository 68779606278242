import { isEmpty, includes } from "lodash";

export const determineBudget = (budget: string): number | null => {
  if(!isEmpty(budget)){ return parseFloat(budget)} 

  return null
}

export const quizCpuScore = (usage: string[]): number => {
  if(isEmpty(usage)){ return 0 }

  switch(true) {
    case includes(usage, 'modeling'):
      return 7
    case includes(usage, 'gaming'):
      return 6
    case includes(usage, 'video'):
      return 6
    case includes(usage, 'photography'):
      return 6
    case includes(usage, 'movies'):
      return 4
    case includes(usage, 'documents'):
      return 3
    case includes(usage, 'browsing'):
      return 2
    default:
      return 0
  }
}

export const quizGpuScore = (usage: string[]): number => {
  if(isEmpty(usage)){ return 0 }
  
  switch(true) {
    case includes(usage, 'modeling'):
      return 8
    case includes(usage, 'gaming'):
      return 7
    case includes(usage, 'video'):
      return 7
    case includes(usage, 'photography'):
      return 5
    default:
      return 0
  }
}

export const quizPortabilityScore = (portability: string): number => {
  switch(portability) {
    case 'never':
      return 0
    case 'occasionally':
      return 5
    case 'often':
      return 7
    default:
      return 0
  }
}

export const quizScreenSize = (size: string): {min?: number, max?: number} | null => {

  switch (size) {
    case 'none':
      return null
    case 'xs':
      return {max: 12}
    case 'sm':
      return {min: 12, max: 13.9}
    case 'md': 
      return {min: 14, max: 15.6}
    case 'lg':
      return {min: 15.6}
    default:
      return null
  }
}

export const mapSelectionsToRequestParams = (selections: {[key: string]: string[]}) => {
  const portability = selections['portability'] ? selections['portability'][0] : ''
  const price = selections['price'] ? selections['price'][0] : 1000
  const screenSize = selections['size'] ? selections['size'][0] : 'none'

  return {
    cpuScore: quizCpuScore(selections['performance']),
    gpuScore: quizGpuScore(selections['performance']),
    portabilityScore: quizPortabilityScore(portability),
    screenSize: quizScreenSize(screenSize),
    price
  }
}