import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Laptop } from 'types/laptop'
import ky from 'ky'
import Grid from '@mui/material/Unstable_Grid2'
import ProductInfo from "components/Laptops/ProductInfo"
import ProductBreakdown from 'components/Laptops/ProductBreakdown'
import ProductSummary from "components/Laptops/ProductSummary"
import ProductSpecifications from "components/Laptops/ProductSpecifications"
import PriceHistory from "components/PriceHistory"
import SimilarProducts from "components/Laptops/SimilarProducts"
import Card from "components/Card"
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout"
import LoadingPage from "components/LoadingPage"
import { generateLaptopName } from "utils/laptop"

const LaptopProduct = () => {
  const location = useLocation()
  const [laptop, setLaptop] = useState<Laptop>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const laptopString: string = location.pathname.split('/laptops/')[1]
    getLaptop(laptopString)
  }, [location.pathname])

  const getLaptop = async (laptopString: string): Promise<void> => {
    await ky.get(`${process.env.REACT_APP_API_URL}/api/findlaptop/${laptopString}`).json<Laptop>()
    .then((data) => setLaptop(data))
    .catch((err) => {console.log(err)}) // TODO: 404?
    .finally(() => setLoading(false))
  }

  if(loading || !laptop) {
    return <LoadingPage/>
  }

  const seoMetaTags: SeoMetaTags = {
    title: `Review: ${generateLaptopName(laptop)} | SpecRanks`,
    description: `See our review and breakdown of the ${generateLaptopName(laptop)}. Compare it's specifications against the competititon with our dynamic ranking system.`
  }

  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Grid container spacing={4}>
        <Grid xs={12} md={8}>
          <Card><ProductInfo product={laptop} /></Card>
          <Card><ProductBreakdown product={laptop} /></Card>
          <Card><ProductSummary product={laptop} /></Card>
          <Card><ProductSpecifications product={laptop} /></Card>
          {laptop.priceList && <Card><PriceHistory priceList={laptop.priceList}/></Card>}
        </Grid>
        <Grid xs={12} md={4}>
          <Card sx={{position: 'sticky', top: '24px'}}><SimilarProducts currentProduct={laptop}/></Card>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default LaptopProduct