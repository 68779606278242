import React, { useState } from 'react'
import {AppBar, Box, Button, Container, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, } from '@mui/material'
import {colors} from 'theme'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

type NavigationBarProps = {
}

type Pages = {
  title: string
  link: string
}

const pages: Pages[] = [
  {
    title: 'Home',
    link: '/laptops'
  },
  {
    title: 'Compare',
    link: '/laptops/compare'
  },
  {
    title: 'Search',
    link: '/laptops/search'
  },
  {
    title: 'Laptop Finder',
    link: '/laptops/finder'
  },
]

const NavigationBar = (props: NavigationBarProps): React.ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} mt={4}>
      <Link to="/" style={{textDecoration: 'none'}}>
        <Typography color={colors.blue50}>Spec<span style={{fontWeight: 900}}>Ranks</span></Typography>
      </Link>
      <List>
        {pages.map((page, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton component={Link} to={page.link} sx={{ textAlign: 'center' }}>
              <ListItemText primary={page.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  
  return (
    <>
      <AppBar position="static" sx={{borderBottom: '1px solid #ededf3'}}>
        <Container>
          <Toolbar disableGutters>
            <Link to="/" style={{textDecoration: 'none'}}>
              <Typography color={colors.blue50}>Spec<span style={{fontWeight: 900}}>Ranks</span></Typography>
            </Link>
            <Box ml={4} sx={{display: {xs: 'none', md: 'flex'}}}>
              {pages.map((page, i) => (
                <Link key={i} to={page.link} style={{textDecoration: 'none'}}>
                  <Button
                    onClick={handleDrawerToggle}
                  >
                    <Typography>{page.title}</Typography>
                  </Button>
                </Link>
              ))}
            </Box>
            <Box display="flex" width="100%" justifyContent="end" sx={{display: {xs: 'flex', md: 'none'}}}>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon/>
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
      }}
    >
      {drawer}
    </Drawer>
  </>
  )
}

export default NavigationBar