import { Box, Typography, Button } from "@mui/material";
import { Laptop } from "types/laptop";
import Grid from '@mui/material/Unstable_Grid2'
import {generateLaptopImageUrl} from 'utils/laptop'
import {colors} from 'theme'

type ProductInfoProps = {
  product: Laptop;
  reverse?: boolean;
  titleAlign?: 'left' | 'right';
  vertical?: boolean;
}

export default function ProductInfo(props: ProductInfoProps) {
  return (
    <>
      <Typography fontSize={30} fontWeight={300}>Winner</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2} mb={2} />
      <Grid 
        container
        spacing={2}
        alignItems="center" 
        height={props.vertical ? '100%' : 'auto'} 
        justifyContent={props.vertical ? 'space-between' : 'auto'} 
        flexDirection={props.vertical ? 'column' : 'row'}
      >
        <Grid sm={12} md={props.vertical ? 12 : 6 }>
          <img alt="laptop" src={generateLaptopImageUrl(props.product)} style={{maxHeight: 200, maxWidth: '100%'}}/>
        </Grid>
        <Grid sm={12} md={props.vertical ? 12 : 6}>
          <Typography fontSize={24} fontWeight={200} textAlign='left' noWrap>{props.product.brand} {props.product.title}</Typography>
          {props.product.model && <Typography fontSize={10} fontWeight={300} textAlign="left">Model: {props.product.model}</Typography>}
          <Box display="flex" flexDirection='row' mt={1} mb={3}>
            <Box display="flex" flexDirection="column" flexShrink={0} justifyContent="center" alignItems="center" mr={2} sx={{
              background: colors.green40,
              height: 70,
              width: 70,
              color: 'white'}}>
              <Typography fontSize={28} fontWeight={300} lineHeight={1} color="white">{props.product.overallScore}</Typography>
            </Box>
            <Box display="flex" alignItems='start' flexDirection="column" justifyContent="center">
              <Typography fontSize={24} fontWeight={200} lineHeight={1.2} textAlign={props.reverse ? 'right' : 'left'}>Overall Score</Typography>
              <Box display="flex" flexDirection='row'>
                <Typography style={{color: colors.red}} fontSize={14}>{props.product.performanceScore}</Typography>
                <Typography fontSize={14} mx={0.8}>Performance Score</Typography>
              </Box>
              <Box display="flex" flexDirection='row'>
                <Typography style={{color: colors.blue60}} fontSize={14}>{props.product.portabilityScore}</Typography>
                <Typography fontSize={14} mx={0.8}>Portability Score</Typography>
              </Box>
            </Box>
          </Box>
          <Button fullWidth sx={{
            background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90% )',
            color: 'white',
            borderRadius: 4,
          }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" py={1} px={1}>
              <Typography color="white" fontWeight={600}>${Number(props.product.listprice).toFixed(2)}</Typography>
              <img src={require('images/amazon-white.png')} alt="Purchase on Amazon" height={20}/>
            </Box>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

