import { Typography } from "@mui/material";
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";

const seoMetaTags: SeoMetaTags = {
  title: 'Disclosure | Find The Best Electronics For You',
  description: '34'
}

export default function Disclosure() {

  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Typography textAlign="left" mt={8} variant="h1" fontSize={32} fontWeight={500}>Disclosure</Typography><br/>
      <Typography textAlign="left">Spec Ranks receives compensation through affiliate programs to fund site maintenance and feature development. Our relationship with these programs in no way affects rankings or opinions on devices, manufacturers, and partners. The mission is to provide functionality for users without regard to commissions.</Typography><br/>
      <Typography textAlign="left">We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</Typography>
    </PageLayout>
  )
}