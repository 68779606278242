import React, { useState } from "react";
import { Box, Button, TextField, Typography, Autocomplete } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import { Formik, Form } from "formik";
import { Laptop } from "types/laptop";
import ky from "ky";
import { debounce, isEmpty } from "lodash";
import { generateLaptopComparisonUrl, generateLaptopName } from "utils/laptop";
import {useNavigate} from 'react-router-dom'
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";

const seoMetaTags: SeoMetaTags = {
  title: 'Compare Laptops Side by Side | Find The Best Laptops For You',
  description: "Compare laptops side by side. Our dynamic ranking system makes it easy to compare laptops. We'll pick the comparison winner for you."
}

export default function Compare() {
  const [firstLaptop, setFirstLaptop] = useState<string>('')
  const [firstLaptopOptions, setFirstLaptopOptions] = useState<string[]>([])
  const [secondLaptop, setSecondLaptop] = useState<string>('')
  const [secondLaptopOptions, setSecondLaptopOptions] = useState<string[]>([])
  const navigate = useNavigate()

  const handleSubmit = () => {
    if(!firstLaptop || !secondLaptop) { return }

    const comparisonUrl = generateLaptopComparisonUrl(firstLaptop, secondLaptop)
    navigate(comparisonUrl)
  }

  const getLaptopOptions = async (query: string): Promise<Laptop[]> => {
    try {
      const data = await ky.get(`${process.env.REACT_APP_API_URL}/api/compareautocomplete`, {
        searchParams: {
          q: query
        }
      }).json<Laptop[]>()
      return data
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const getOptions = async (query: string, stateUpdate: React.Dispatch<React.SetStateAction<string[]>>): Promise<void> => {
    if(isEmpty(query)) { return }

    const options = await getLaptopOptions(query)
    stateUpdate(options.map(generateLaptopName))
  }

  const handleInputChange = debounce(getOptions, 500)

  return (
    <PageLayout seoMetaTags={seoMetaTags} containerSx={{justifyContent: 'center'}}>
      <Typography fontWeight={600} fontSize={36}>Compare laptops side by side.</Typography>
      <Typography mt={2}>The ultimate laptop comparison tool. We'll pick a winner for you.</Typography>
      <Formik
        initialValues={{firstLaptop: '', secondLaptop: ''}}
        onSubmit={handleSubmit}
      >
        <Form style={{width: '100%'}}>
          <Grid container spacing={2} my={3} width="100%">
            <Grid xs={12} sm={6} flexGrow={1}>
              <Autocomplete
                disableClearable
                fullWidth
                id="firstLaptop"
                forcePopupIcon={false}
                options={firstLaptopOptions}
                size="small"
                onChange={(_, value) => setFirstLaptop(value)}
                onInputChange={(_, value) => {
                  if(firstLaptopOptions.indexOf(value) !== -1) { return }
                  handleInputChange(value, setFirstLaptopOptions)
                }}
                renderInput={(params) => 
                  <TextField required {...params}
                    placeholder="First Laptop"
                    name="firstLaptop"
                  />}
              />
            </Grid>
            <Grid xs={12} sm={6}>
            <Autocomplete
                disableClearable
                fullWidth
                id="secondLaptop"
                forcePopupIcon={false}
                options={secondLaptopOptions}
                size="small"
                onChange={(_, value) => setSecondLaptop(value)}
                onInputChange={(_, value) => {
                  if(secondLaptopOptions.indexOf(value) !== -1) { return }
                  handleInputChange(value, setSecondLaptopOptions)
                }}
                renderInput={(params) => <TextField {...params} placeholder="Second Laptop" required />}
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth sx={{
            background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90% )',
            color: 'white',
            borderRadius: 3,
            maxWidth: 150,
          }}>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="100%" py={0.5} >
              <Typography color="white" fontSize={18} fontWeight={500}>Compare</Typography>
            </Box>
          </Button>
        </Form>
      </Formik>
    </PageLayout>
  )
}