import { ThemeOptions, createTheme } from '@mui/material';

export const colors = {
  blue: '#3888FF',
  blue10: '#05162E',
  blue20: '#0A2B5C',
  blue30: '#0F418A',
  blue40: '#1456B8',
  blue50: '#1D6CE2',
  blue60: '#4288F0',
  green10: '#0A2D06',
  green20: '#13590D',
  green30: '#1D8613',
  green40: '#26B21A',
  green50: '#30DF20',
  green: '#46C532',
  red: '#EB4E4E',
  background: '#f7f7f9',
  barGrey: '#ececf2'
}

export const cardStyle = {
  backgroundColor: 'white',
  borderRadius: 2, 
  boxShadow: '0 0 15px #00000016',
}

const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    allVariants: {
      color: '#535f71'
    }
  },
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: '#0C2E61',
      secondary: '#0C2E61',
      disabled: '#737f94',
    },
    background: {default: colors.background}
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'white',
          boxShadow: 'none'
        }
      }
    }
  },
};


export default createTheme(themeOptions)