import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import RefineSlider from "components/RefineSlider";
import { colors } from "theme";
import SortByFilter from "./SortByFilter";

export default function RefineSearch() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      <Typography fontSize={30} fontWeight={200} mb={2}>Refine Search</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mb={2} width="50%" />
      {isMobile && 
        <Box mb={2}>
          <SortByFilter fontSize={18} />
        </Box>   
      }
      <RefineSlider 
        title='Price Range'
        defaultValue={[0, 3000]}
        labelFormat={(value) => `$${value}`}
        fieldName='priceRange'
        step={100}
        max={3000}
      />
      <RefineSlider 
        title='Screen size'
        defaultValue={[0, 19]}
        fieldName='screenSizeRange'
        labelFormat={(value) => `${value}"`}
        max={19}
      />
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2} mb={3}/>
      <RefineSlider title='Overall Score' fieldName='overallScoreRange' />
      <RefineSlider title='Performance Score' fieldName='performanceScoreRange'  />
      <RefineSlider title='Portability Score' fieldName='portabilityScoreRange' />
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2} mb={3}/>
      <RefineSlider title='Display Score' fieldName='displayScoreRange' />
      <RefineSlider title='Processor Score' fieldName='cpuScoreRange' />
      <RefineSlider title='Graphics Score' fieldName='gpuScoreRange' />
      <RefineSlider title='Memory Score' fieldName='ramScoreRange' />
      <RefineSlider title='Harddrive Score' fieldName='harddriveScoreRange' />
      <RefineSlider title='Weight Score' fieldName='weightScoreRange' />
      <RefineSlider title='Thickness Score' fieldName='thicknessScoreRange' />
      <RefineSlider title='Size Score' fieldName='sizeScoreRange' />
      {/* <RefineSlider title='Battery Score' fieldName='batteryScoreRange' /> */}
    </Box>
  )
}