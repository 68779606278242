import { Box, Typography } from "@mui/material"
import { colors } from "theme"
import { Laptop } from "types/laptop"

type ScoreSummariesProps = {
  reverse?: boolean 
  product: Laptop
  mb?: number
}

export default function ScoreSummaries(props: ScoreSummariesProps) {
  const flexRow = props.reverse ? 'row-reverse' : 'row'
  const alignItems = props.reverse ? 'end' : 'start'

  return (
    <Box display="flex" flexDirection={flexRow} flexWrap="wrap" mt={1} mb={props.mb || 0}>
      <Box display="flex" flexDirection="column" flexShrink={0} justifyContent="center" alignItems="center" 
      ml={props.reverse ? 2 : 0}
      mr={props.reverse ? 0 : 2}
      mb={1}
      sx={{
        background: colors.green40,
        height: 70,
        width: 70,
        borderRadius: 1,
        color: 'white'}}>
        <Typography fontSize={28} fontWeight={500} lineHeight={1} color="white">{props.product.overallScore}</Typography>
      </Box>
      <Box display="flex" alignItems={alignItems} flexDirection="column" justifyContent="center">
        <Typography fontSize={24} fontWeight={200} lineHeight={1.2} textAlign={props.reverse ? 'right' : 'left'}>Overall Score</Typography>
        <Box display="flex" flexDirection={flexRow}>
          <Typography style={{color: colors.red}} fontSize={14}>{props.product.performanceScore}</Typography>
          <Typography fontSize={14} mx={0.8}>Performance <Box display={{xs: 'none', sm: 'inline-block'}}>Score</Box></Typography>
        </Box>
        <Box display="flex" flexDirection={flexRow}>
          <Typography style={{color: colors.blue60}} fontSize={14}>{props.product.portabilityScore}</Typography>
          <Typography fontSize={14} mx={0.8}>Portability <Box display={{xs: 'none', sm: 'inline-block'}}>Score</Box></Typography>
        </Box>
      </Box>
    </Box>
  )
}