import { Box, Typography } from "@mui/material"
import {colors } from "theme"
import { Laptop } from "types/laptop"
import RatingCategory, {RatingCategoryProps} from "components/RatingCategory";

type ProductSummaryProps = {
  product: Laptop
}

export default function ProductSummary(props: ProductSummaryProps) {
  const summaryCategories: RatingCategoryProps[] = [
    {
      title: 'Performance',
      subTitle: 'based on processor, graphics, and memory',
      score: props.product.performanceScore,
      barColor: colors.red
    },
    {
      title: 'Portability',
      subTitle: 'based on weight, size, and battery life',
      score: props.product.portabilityScore,
      barColor: colors.blue60
    },
    {
      title: 'Overall',
      subTitle: 'Average of summary scores',
      score: props.product.overallScore,
      barColor: colors.green40
    }
  ]

  return (
    <Box mb={2}>
      <Typography fontSize={30} fontWeight={200}>Laptop Summary</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      {summaryCategories.map((category, i) => {
        return <RatingCategory key={i} {...category}/>
      })}
    </Box>
  )
}