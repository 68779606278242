import { Box, Button, Typography } from "@mui/material";
import {Link} from 'react-router-dom'
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";

const backgroundImage = require(`images/LaptopFinderHeader.jpg`)

const heroSection = (
  <Box display="flex" height="50vh" alignItems="center" justifyContent="center" flexDirection="column" mb={6}
    sx={{
      background: `url(${backgroundImage}) 0% 0% / cover no-repeat fixed rgba(0, 0, 0, 0.8)`,
      backgroundBlendMode: 'multiply'
    }}
  >
    <Typography color="white" fontSize={32} fontWeight={500}>Let's find the right laptop for you.</Typography>
    <Typography color="white" mt={1}>Based on your needs, we'll help you make a decision.</Typography>
    <Link to="/laptops/quiz">
      <Button
        sx={{
          background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90%)',
          borderRadius: 8,
          px: 8,
          py: 1.5,
          mt: 4
        }}
      >
        <Typography color="white">Go!</Typography>
      </Button>
    </Link>
  </Box>
)

const seoMetaTags: SeoMetaTags = {
  title: 'Laptop Finder | Interactive Buying Guide',
  description: 'Our laptop finder will suggest a laptop based on your specific usage. Take our simple questionaire and get laptop suggestions specifically for you.'
}

export default function Finder() {
  return (
    <PageLayout heroSection={heroSection} seoMetaTags={seoMetaTags} maxWidth="md">
      <Typography variant="h1" fontSize={48} fontWeight={400}>The Laptop Finder</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>Finding the perfect laptop could be a daunting task, but our state of the art laptop finder helps you with personalized laptop recommendations. With devices ranging from $200 to $2000+, containing confusing specifications, different configurations, and more, it can be extremely difficult to pick the right laptop for your needs. Our laptop finder tool narrows it down by getting more familiar with you and your needs. We'll then use this information to find the perfect laptop for you.</Typography><br/>
      <Typography variant="h2" fontSize={48} fontWeight={300}>The Laptop Quiz</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>At the core of our laptop finder is a laptop quiz. This quiz helps us understand you and your use cases. We'll use this information to assemble a list of your needs that we'll use to search our laptop database. Behind the scenes, we have an extremely powerful laptop ranking system. We use this system to break down each device by its performance benchmarks, size, battery life, overall value and more. This system is ever evolving and stays up to date based on the latest and greatest specs. Therefore, by pairing our laptop data with your personalized quiz results, we can help you find the perfect laptop.  Some of the questions we ask in our laptop quiz are listed below:</Typography><br/>
      <Typography variant="h2" fontSize={36} fontWeight={300}>What will you be using your laptop for?</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>Your use case with a laptop is one of the most important things to understand before going laptop shopping. Take some time to think about all the tasks you'll need to do on your laptop. Will you be gaming, writing documents, watching movies, editing multimedia, and more? Each one of these tasks have different specification requirements. For example, gaming will generally have high graphic requirements. Multimedia editing such as photography, video, and audio will have higher CPU requirements. Taking all of these into consideration in our laptop finder is key to giving you the best suggestion possible.</Typography><br/>

      <Typography variant="h2" fontSize={36} fontWeight={300}>How often will you carry your laptop with you?</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>Your need for portability will greatly influence your decision with a laptop. If you're someone who will need to travel often with your laptop, considerations about battery life, weight, and overall size should be considered. In the case that you'll be mostly stationary with your laptop, perhaps things like battery life and weight won't matter as much. In combination with what you'll be using your laptop for, we'll find the best balance of power and portability in our suggestion.</Typography><br/>

      <Typography variant="h2" fontSize={36} fontWeight={300}>Do you have a screen size preference?</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>Although portability is considered in the previous question, screen size can sometimes be a personal preference. In the case that you do have a preference, you are able to select the perfect screen size for you. The most common sizes are between 13.3 inches to 15.9 inches, where screen real estate and weight falls in the mainstream. You're more than welcome to choose which in this range you prefer, or even sizes outside of this range if you wish. If you have no preference, an option exists where our laptop finder will prioritize other considerations to find you the best laptop for you no matter what size.</Typography><br/>

      <Typography variant="h2" fontSize={36} fontWeight={300}>What is the most you're willing to spend?</Typography><br/>
      <Typography fontWeight={300} fontSize={18}>Your budget is the final determining factor. In many cases, the perfect laptop is impossible to determine without considering your spending range, because there'll always be a better and more expensive laptop! Considering the factors above, we'll pick the absolute best laptop for you within this price range. If there's a better laptop that's available at a lower price, we'll make sure to consider that in the final selection.</Typography><br/>
    </PageLayout>
  )
}





