import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { ExpandMore } from '@mui/icons-material'
import { useFormikContext } from "formik";
import { SearchQuery } from "views/laptops/Search";
import { isUndefined } from 'lodash'
import { useSearchParams } from "react-router-dom";
import { getKeyByValue } from "utils/generalUtils";

export const sortOptions = [
  'Best Overall',
  'Best Performance',
  'Best Portability',
  'Price: High to Low',
  'Price: Low to High',
]

export const sortMap: {[key: string]: string} = {
  overall: 'Best Overall',
  performance: 'Best Performance',
  portability: 'Best Portability',
  priceDesc: 'Price: High to Low',
  priceAsc: 'Price: Low to High',
}

type SortByFilterProps = {
  fontSize?: number
}

export default function SortByFilter(props: SortByFilterProps) {
  const {values, setFieldValue, handleSubmit} = useFormikContext<SearchQuery>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [_, setSearchParams] = useSearchParams()

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sortOption?: string) => {
    setAnchorEl(null);

    if(!isUndefined(sortOption) && sortOption !== values.sortBy) {
      setSearchParams({sort: getKeyByValue(sortMap, sortOption) || ""})
      setFieldValue('sortBy', sortOption || values.sortBy)
      handleSubmit()
    }
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Typography fontSize={props.fontSize} lineHeight={1}>Sort By:&nbsp;<span>{values.sortBy}</span></Typography><ExpandMore sx={{lineHeight: 1}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {sortOptions.map((option) => (
          <MenuItem onClick={() => handleClose(option)}>{option}</MenuItem>
        ))}
      </Menu>
    </>
  );
}

SortByFilter.defaultProps = {
  fontSize: 14
}