import { Box, SxProps } from "@mui/material";
import { Link } from "react-router-dom";
import { cardStyle } from "theme";

type CardProps = {
  children: React.ReactNode
  sx?: SxProps
  mt?: number
  py?: number
  px?: number
  link?: string
}
export default function Card(props: CardProps) {
  const CardComponent = (
    <Box 
      display="flex" 
      flexDirection="column"
      mt={props.mt}
      py={props.py}
      px={props.px}
      sx={{...cardStyle, ...props.sx}}
      >
      {props.children}
    </Box>
  )

  if(props.link){
    return (
      <Link to={props.link} style={{color: 'inherit', textDecoration: 'none'}}>
        {CardComponent}
      </Link>
    )
  }

  return CardComponent
}

Card.defaultProps = {
  mt: 4,
  py: 3,
  px: 3
}