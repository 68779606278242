import { Box, Typography } from "@mui/material"
import RatingCategory, { RatingCategoryProps } from "components/RatingCategory"
import { compact } from "lodash"
import { colors } from "theme"
import { Laptop } from "types/laptop"

type ComparisonBreakdownProps = {
  firstProduct: Laptop
  secondProduct: Laptop
}

const batteryBreakdown = (firstProduct: Laptop, secondProduct: Laptop) => {
  if (!firstProduct.battery && !secondProduct.battery) return null

  return {
    title: 'Battery Life',
    subTitle: 'based on benchmarks',
    barText: `${firstProduct.battery} hours`,
    score: firstProduct.batteryScore,
    secondBarText: `${secondProduct.battery} hours`,
    secondScore: secondProduct.batteryScore
  }
}

export default function ComparisonBreakdown(props: ComparisonBreakdownProps) {
  const breakdownCategories = compact([
    {
      title: 'Display Sharpness',
      subTitle: 'based on pixel density',
      barText: `${props.firstProduct.displayDensity} PPI`,
      score: props.firstProduct.displayScore,
      secondBarText: `${props.secondProduct.displayDensity} PPI`,
      secondScore: props.secondProduct.displayScore
    },
    {
      title: 'Processor Speed',
      subTitle: 'based on benchmarks',
      barText: props.firstProduct.cpuName,
      score: props.firstProduct.cpuScore,
      secondBarText: props.secondProduct.cpuName,
      secondScore: props.secondProduct.cpuScore

    },
    {
      title: 'Graphics',
      subTitle: 'based on benchmarks',
      barText: `${props.firstProduct.gpuBrand} ${props.firstProduct.gpuName}`,
      score: props.firstProduct.gpuScore,
      secondBarText: `${props.secondProduct.gpuBrand} ${props.secondProduct.gpuName}`,
      secondScore: props.secondProduct.gpuScore
    },
    {
      title: 'Memory',
      subTitle: 'based on memory size',
      barText: `${props.firstProduct.ramSize} GB`,
      score: props.firstProduct.ramScore,
      secondBarText: `${props.secondProduct.ramSize} GB`,
      secondScore: props.secondProduct.ramScore
    },
    {
      title: 'Storage Class',
      subTitle: 'based on drive type',
      barText: props.firstProduct.harddriveType,
      score: props.firstProduct.harddriveScore,
      secondBarText: props.secondProduct.harddriveType,
      secondScore: props.secondProduct.harddriveScore,
    },
    batteryBreakdown(props.firstProduct, props.secondProduct),
    {
      title: 'Weight',
      subTitle: 'based on manufacturer\'s listing',
      barText: `${props.firstProduct.weight} lbs.`,
      score: props.firstProduct.weightScore,
      secondBarText: `${props.secondProduct.weight} lbs.`,
      secondScore: props.secondProduct.weightScore
    },
    {
      title: 'Slimness',
      subTitle: 'based on manufacturer\'s listing',
      barText: `${props.firstProduct.thickness} in.`,
      score: props.firstProduct.thicknessScore,
      secondBarText: `${props.secondProduct.thickness} in.`,
      secondScore: props.secondProduct.thicknessScore
    },
    {
      title: 'Overall Size',
      subTitle: 'based on manufacturer\'s listing',
      barText: <>{props.firstProduct.size} in <sup>3</sup></>,
      score: props.firstProduct.sizeScore,
      secondBarText: <>{props.secondProduct.size} in <sup>3</sup></>,
      secondScore: props.secondProduct.sizeScore
    }
  ]) as RatingCategoryProps[]

  return (
    <>
      <Typography fontSize={30} fontWeight={300}>Laptop Breakdown</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      {breakdownCategories.map((category, i) => {
        return <RatingCategory key={i} {...category} isComparison/>
      })}
    </>
  )
}