import { Box, Breakpoint, Container, SxProps } from "@mui/material";
import Footer from "components/Footer";
import NavigationBar from "components/NavigationBar";
import React, { useEffect } from "react";
import {motion} from 'framer-motion'
import { Helmet } from 'react-helmet-async'
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'

export type SeoMetaTags = {
  title: string
  description: string
}

type PageLayoutProps = {
  children: React.ReactNode
  hideFooter?: boolean
  containerSx?: SxProps
  heroSection?: JSX.Element 
  maxWidth?: Breakpoint
  pageTitle?: string
  seoMetaTags: SeoMetaTags
}

export default function PageLayout(props: PageLayoutProps) {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname});
  }, [location])
  
  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} >
      <Helmet>
        <title>{props.seoMetaTags.title}</title>
        <meta name="description" content={props.seoMetaTags.description} />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta charSet="UTF-8"/>
      </Helmet>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <NavigationBar/>
        {props.heroSection}
        <Container maxWidth={props.maxWidth} sx={{display: "flex", flexDirection: 'column', flexGrow: 1, ...props.containerSx}}>
          {props.children}
        </Container>
        {!props.hideFooter && <Footer/>}
      </Box>
    </motion.div>
  )
}

PageLayout.defaultProps = {
  maxWidth: 'lg'
}