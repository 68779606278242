import { Box, Typography } from "@mui/material";
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import {colors} from 'theme'

type RatingBarProps = {
  score?: string;
  color?: string;
  barText?: string | React.ReactElement
  reverse?: boolean
  hideScore?: boolean
  mt?: number
}

export default function RatingBar(props: RatingBarProps) {
  const [barPercentage, setBarPercentage] = useState('0%')
  const color = props.color || colors.blue60

  useEffect(() => {
    setBarPercentage(props.score ? (Number(props.score) * 10).toFixed(0).toString() + '%' : '0%')
  }, [props.score])
  
  return (
    <Box flexGrow={1} mt={isNull(props.mt) ? 1 : props.mt}>
      <Typography lineHeight={1} fontSize={14} textAlign={props.reverse ? 'right' : 'left'} color="#aaaaaa">{props.score ? props.barText : 'n/a'}</Typography>
      <Box display="flex" flexDirection={props.reverse ? 'row-reverse' : 'row'} alignItems="center" justifyContent="center">
        <Box display="flex" flexGrow={1} height={10} justifyContent={props.reverse ? "end" : "start"} sx={{backgroundColor: colors.barGrey}} mt={isNull(props.mt) ? 1 : props.mt}>
          <Box width={barPercentage} height={10} sx={{background: color, transition: 'width 3s ease 0s'}}></Box>
        </Box>
        {!props.hideScore &&
          <Box ml={props.reverse ? 0 : 2} mr={props.reverse ? 2 : 0}>
            <Typography fontWeight={700} color={color}>{props.score || 'n/a'}</Typography>
          </Box>
        }  
      </Box>
    </Box>
  )
}