import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Laptop } from "types/laptop"
import ProductInfo from "components/Laptops/ProductInfo"
import Grid from '@mui/material/Unstable_Grid2'
import ky from 'ky'
import Card from "components/Card"
import ComparisonBreakdown from "components/Laptops/ComparisonBreakdown"
import ComparisonSummary from "components/Laptops/ComparisonSummary"
import ComparisonWinner from "components/Laptops/ComparisonWinner"
import ComparisonSpecifications from "components/Laptops/ComparisonSpecifications"
import PriceHistory from "components/PriceHistory"
import SimilarProducts from "components/Laptops/SimilarProducts"
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout"
import LoadingPage from "components/LoadingPage"
import { generateLaptopName } from "utils/laptop"

export default function Comparison() {
  const location = useLocation()
  const [firstLaptop, setFirstLaptop] = useState<Laptop>()
  const [secondLaptop, setSecondLaptop] = useState<Laptop>()

  useEffect(() => {
    const laptopString: string[] = location.pathname.split('/laptops/comparison')[1].split('-vs-')
    getLaptops(laptopString[0], laptopString[1])
  }, [location])

  const getLaptops = async (firstLaptopString: string, secondLaptopString: string): Promise<void> => {
    await ky.get(`${process.env.REACT_APP_API_URL}/api/findlaptop/${firstLaptopString}`).json<Laptop>()
    .then((data) => setFirstLaptop(data))
    .catch((err) => console.log(err))

    await ky.get(`${process.env.REACT_APP_API_URL}/api/findlaptop/${secondLaptopString}`).json<Laptop>()
    .then((data) => setSecondLaptop(data))
    .catch((err) => console.log(err))

    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  if(!firstLaptop || !secondLaptop) {
    return <LoadingPage/>
  }

  const seoMetaTags: SeoMetaTags = {
    title: `Compare: ${generateLaptopName(firstLaptop)} VS ${generateLaptopName(secondLaptop)}`,
    description: `Compare the ${generateLaptopName(firstLaptop)} and the ${generateLaptopName(secondLaptop)} side by side to see how they rank against each other. Our ranking system will choose the best laptop for you.`
  }

  const winningLaptop = Number(firstLaptop.overallScore) < Number(secondLaptop.overallScore) ? secondLaptop : firstLaptop

  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Card>
        <Grid container spacing={1}>
          <Grid xs={6}><ProductInfo product={firstLaptop} reverse vertical/></Grid>
          <Grid xs={6}><ProductInfo product={secondLaptop} vertical /></Grid>
        </Grid>
      </Card>
      <Card><ComparisonBreakdown firstProduct={firstLaptop} secondProduct={secondLaptop} /></Card>
      <Card><ComparisonSummary firstProduct={firstLaptop} secondProduct={secondLaptop} /></Card>
      <Card><ComparisonWinner product={winningLaptop}/></Card>
      <Card><ComparisonSpecifications firstProduct={firstLaptop} secondProduct={secondLaptop} /></Card>
      <Card><PriceHistory priceList={firstLaptop.priceList} secondPriceList={secondLaptop.priceList} /></Card>
      <Card><SimilarProducts currentProduct={winningLaptop} flexDirection='row' /></Card>
  </PageLayout>
  )
}