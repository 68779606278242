import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from '@mui/system';
import Theme from 'theme'
import { CssBaseline } from '@mui/material';
import { LaptopRoutes } from 'routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4'

ReactGA.initialize('G-G8ZC2FVTJX')

const router = createBrowserRouter([
  ...LaptopRoutes
]);

const queryClient = new QueryClient()

const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={Theme}>
          <HelmetProvider>
            <CssBaseline/>
            <RouterProvider router={router} />
          </HelmetProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
