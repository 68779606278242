import { Box, Typography, Button } from "@mui/material";
import { Laptop } from "types/laptop";
import Grid from '@mui/material/Unstable_Grid2'
import {generateLaptopImageUrl, generateLaptopProductUrl} from 'utils/laptop'
import ScoreSummaries from "./ScoreSummaries";
import PriceText from "components/PriceText";
import { Link } from "react-router-dom";

type ProductInfoProps = {
  product: Laptop;
  reverse?: boolean;
  titleAlign?: 'left' | 'right';
  vertical?: boolean;
}

export default function ProductInfo(props: ProductInfoProps) {
  return (
    <Box>
      <Link to={generateLaptopProductUrl(props.product)} style={{textDecoration: 'none'}}>
        <Typography fontSize={24} fontWeight={200} textAlign={props.titleAlign} noWrap>{props.product.brand} {props.product.title}</Typography>
      </Link>
      <Grid 
        container
        alignItems="center" 
        height={props.vertical ? '100%' : 'auto'} 
        justifyContent={props.vertical ? 'space-between' : 'auto'} 
        flexDirection={props.vertical ? 'column' : 'row'}
      >
        <Grid xs={12} md={props.vertical ? 12 : 6 }>
          <Link to={generateLaptopProductUrl(props.product)}>
            <img alt="laptop" src={generateLaptopImageUrl(props.product)} style={{maxHeight: 200, maxWidth: '100%'}}/>
          </Link>
        </Grid>
        <Grid xs={12} md={props.vertical ? 12 : 6}>
          {props.product.model &&
            <Typography fontSize={10} fontWeight={300} textAlign={props.reverse ? 'right' : 'left'} noWrap>Model: {props.product.model}</Typography>
          }
          <ScoreSummaries product={props.product} reverse={props.reverse} mb={3} />
          <a target="_blank" rel="noopener noreferrer" href={props.product.url} style={{textDecoration: 'none'}}>
            <Button fullWidth sx={{
              background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90% )',
              color: 'white',
              borderRadius: 8,
            }}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" flexWrap="wrap-reverse" py={1} px={2}>
                <PriceText price={props.product.listprice} sx={{color: 'white'}} />
                <img src={require('images/amazon-white.png')} alt="Purchase on Amazon" height={20}/>
              </Box>
            </Button>
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}

ProductInfo.defaultProps = {
  titleAlign: 'center'
}
