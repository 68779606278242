import { Typography } from "@mui/material";
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";

const seoMetaTags: SeoMetaTags = {
  title: 'About SpecRanks | Find The Best Laptop For You',
  description: 'Learn more about SpecRanks'
}

export default function About() {
  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Typography textAlign="left" mt={8} variant="h1" fontSize={32} fontWeight={500}>About SpecRanks</Typography>
      <Typography textAlign="left" mt={8} variant="h2" fontSize={24} fontWeight={500}>Search, sort, and compare products.</Typography><br/>
      <Typography textAlign="left">SpecRanks provides deep searching, sorting, and comparing of electronics for future buyers. Using our intuitive ranking system, understanding what a product offers hasn't been easier. We do all the thinking regarding computer components, benchmarks, and specifications for you, and deliver to you a simple number ranking.</Typography><br/>

      <Typography textAlign="left" mt={8} variant="h2" fontSize={24} fontWeight={500}>Why should I care about rankings?</Typography><br/>
      <Typography textAlign="left">Shopping for electronics can be such a difficult task because of the standard specification list. These lists typically include part numbers (i.e. a processor or graphics card) and other specs that simply require more research and analysis to understand. Other numbers and terms in the specification list can be deceptive, such as screen resolution and processor clock speed. Even once an understanding of the specs has been established, it may be difficult to asses how a product compares to other competing products. SpecRanks features a ranking system that answers all the problems of the standard specification list. Each element of a product is ranked out of 10, based on specifications and benchmarks. This allows you to understand a particular product's capabilities at a quick glance, and also lets you know how that product compares with what the market has to offer.</Typography><br/>

      <Typography textAlign="left" mt={8} variant="h2" fontSize={24} fontWeight={500}>How are rankings determined?</Typography><br/>
      <Typography textAlign="left">Each category uses mathematical formulas along with real world benchmarks, specifications, and measurements to determine rankings amongst all available data points. Performance and portability scores are weighted averages, placing more weight on categories that are more important for the respective score. Overall scores are normalized averages, placing the best products at the very top of the scale.</Typography><br/>
    </PageLayout>
  )
}