import { Box, Slider, Typography } from "@mui/material"
import { useFormikContext } from "formik"

type RefineSliderProps = {
  title?: string
  defaultValue?: [number, number]
  fieldName: string
  labelFormat: (value: number) => string
  step?: number
  min?: number
  max?: number
}

type Values = {
  [key: string]: [number, number]
}

// NOTE: using state instead of formik values seems to be snappier
export default function RefineSlider(props: RefineSliderProps) {
  const {setFieldValue, handleSubmit, values} = useFormikContext<Values>()

  return (
    <Box mb={2} width="100%" px={1}>
      {props.title && <Typography textAlign="center">{props.title}</Typography>}
      <Slider
        defaultValue={props.defaultValue}
        value={values[props.fieldName]}
        onChangeCommitted={(_, value) => {
          setFieldValue(props.fieldName, value)
          handleSubmit()
        }}
        onChange={(_, value) => {
          setFieldValue(props.fieldName, value)
        }}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => props.labelFormat(value)}
        getAriaValueText={(value) => props.labelFormat(value)}
        step={props.step}
        min={props.min}
        max={props.max}
      />
      <Typography textAlign="center" lineHeight={1}>{props.labelFormat(values[props.fieldName][0])} - {props.labelFormat(values[props.fieldName][1])}</Typography>
    </Box>
  )
}

RefineSlider.defaultProps = {
  defaultValue: [0, 10],
  labelFormat: (value: string) => value,
  step: 0.1,
  min: 0,
  max: 10
}