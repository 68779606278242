import { Typography, Box, TextField, InputAdornment } from "@mui/material"
import Card from "components/Card"
import { isEmpty, xor, indexOf } from "lodash"
import { colors } from "theme"
import { Question } from "views/laptops/Quiz"

type QuizQuestionProps = Question & {
  selections: {[key: string]: string[]}
  setSelections: React.Dispatch<React.SetStateAction<{[key: string]: string[]}>>
  singleSelection?: boolean
  resultsCount?: number
}

export default function QuizQuestion(props: QuizQuestionProps) {
  const fontColor = (value: string) => (
    indexOf(props.selections[props.keyName], value) === -1 ? 'inherit' : 'white'
  )

  const selectedBackground = (value: string) => {
    const selection = props.selections[props.keyName]
    if(isEmpty(selection)) {
      return 'inherit'
    }
    return selection.indexOf(value) === -1 ? 'inherit' : 'linear-gradient(45deg, #14458C 10%, #1D64CE 90%)'
  } 

  const toggleSelection = (value: string) => {
    const currentValue = props.selections[props.keyName] || []
    const newValue = props.singleSelection ? [value] : xor(currentValue, [value])

    props.setSelections({
      ...props.selections,
      [props.keyName]: newValue
    })
  }

  return (
    <Box display="flex" flexDirection="column" py={4} mb={10} flexGrow={1}>
      <Typography fontSize={24} fontWeight={500} lineHeight={1.5}>{props.title}</Typography>
      <Typography mt={1} mb={1}>{props.subTitle}</Typography>
      {props.answers.map((answer, i) => (
        <div key={answer.value} onClick={() => toggleSelection(answer.value)} style={{cursor: 'pointer'}}>
          <Card mt={1} py={1} sx={{border: '1px solid #e5e5eb', background: () => selectedBackground(answer.value)}}>
            <Box display="flex" flexDirection="row" alignItems="center" sx={{color: fontColor(answer.value)}}>
              {answer.icon && answer.icon}
              <Box ml={2}>
                <Typography fontWeight={600} textAlign="left" color={fontColor(answer.value)} lineHeight={1.5}>{answer.title}</Typography>
                <Typography fontWeight={300} textAlign="left" color={fontColor(answer.value)}>{answer.subTitle}</Typography>
              </Box>
            </Box>
          </Card>
        </div>))
      }
      {props.input && 
        <TextField 
          placeholder="1000.00"
          type="number"
          value={props.selections[props.keyName]}
          onChange={(e) => toggleSelection(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>
          }}
        />
      }
      {props.resultsCount === 0 && 
        <Typography color={colors.red} mt={2}>No results for your criteria. Try adjusting your budget or selections.</Typography>
      }
    </Box>
  )
}