import {Laptop} from 'types/laptop'
import { isProduction } from './generalUtils';

export const generateLaptopProductUrl = (laptop: Laptop): string => { 
  let productUrl = '/laptops/' + laptop.brand + ' ' + laptop.title;
  if (laptop.model) {
    productUrl += ` ${laptop.model}`.replace(/-/g, '_')
  }

  return productUrl.replace(/\s/g, '-')
}

export const generateLaptopImageUrl = (laptop: Laptop): string => {
  let productImage
  try {
    if(isProduction()) {
      productImage = `https://specranks-images.s3.us-west-2.amazonaws.com/laptops/${laptop.image}`
    } else {
      productImage = require(`images/laptops/${laptop.image}`)
    }
  } catch (err) {
    productImage = require('images/laptops/DefaultLaptop.jpg')
  }

  return productImage
} 

export const calculateDisplayDensity = (laptop: Laptop) => {
  const diagonalPixels = Math.sqrt(Math.pow(laptop.displayWidth, 2) + Math.pow(laptop.displayHeight, 2))
  return (diagonalPixels / Number(laptop.displaySize)).toFixed(0)
}

export const generateLaptopComparisonUrl = (firstLaptop: Laptop | string, secondLaptop: Laptop | string) => {
  return `/laptops/comparison/${generateLaptopName(firstLaptop)}-vs-${generateLaptopName(secondLaptop)}`.replace(/\s/g, '-')
}

export const generateLaptopName = (laptop: Laptop | string) => {
  if(typeof laptop === 'string') {
    return laptop.trim()
  }

  return `${laptop.brand} ${laptop.title}${laptop.model ? ` ${laptop.model}` : ''}`.trim()
}