import { Box, CircularProgress, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { colors } from "theme";
import { Laptop } from "types/laptop";
import Grid from '@mui/material/Unstable_Grid2'
import { generateLaptopComparisonUrl, generateLaptopImageUrl } from "utils/laptop";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ky from "ky";
import PriceText from "components/PriceText";

type SimilarProductsProps = {
  currentProduct: Laptop
  flexDirection?: 'row' | 'column'
}

type SimilarProductProps = {
  product: Laptop
}

const SimilarProduct = (props: SimilarProductProps) => (
  <Grid container px={0} maxWidth="100%">
    <Grid xs={12} >
      <Typography fontSize={16} fontWeight={200} textAlign="left">{props.product.brand} {props.product.title}</Typography>
    </Grid>
    <Grid xs={6} display="flex" alignItems="center" justifyContent="end">
      <img alt="laptop" src={generateLaptopImageUrl(props.product)} style={{maxHeight: 110, maxWidth: '100%'}}/>
    </Grid>
    <Grid xs={6}>
      <Box display="flex" flexDirection="column" mt={1}>
        <Box display="flex" flexDirection="row" mb={2}>
          <Box display="flex" flexDirection="column" flexShrink={0} justifyContent="center" alignItems="center" mr={2} sx={{
            background: colors.green40,
            height: 35,
            width: 35,
            borderRadius: 1,
            color: 'white'}}>
            <Typography fontSize={16} fontWeight={500} lineHeight={1} color="white">{props.product.overallScore}</Typography>
          </Box>
          <Box display="flex" alignItems="start" flexDirection="column" justifyContent="center">
            <Typography fontSize={12} color={colors.red} lineHeight={1.2}>{props.product.performanceScore}</Typography>
            <Typography fontSize={12} color={colors.blue60} lineHeight={1.2}>{props.product.portabilityScore}</Typography>
          </Box>
        </Box>
        <PriceText price={props.product.listprice} sx={{textAlign: 'left'}} />
      </Box>
    </Grid>
  </Grid>
)

export default function SimilarProducts(props: SimilarProductsProps) {
  const [products, setProducts] = useState<Laptop[]>([])

  useEffect(() => {
    if(!props.currentProduct) { return }
    getSimilarProducts()
  }, [props.currentProduct])

  const getSimilarProducts = async () => {
    ky.get(`${process.env.REACT_APP_API_URL}/api/similarpricelaptops`, {
      searchParams: {
        price: props.currentProduct.listprice,
        title: props.currentProduct.title
      }
    }).json<Laptop[]>()
    .then((data) => setProducts(data))
    .catch((err) => {console.log(err)}) // TODO: 404?
  }

  return (
    <>
      <Typography fontSize={30} fontWeight={200} mb={3}>Compare similar laptops</Typography>
      <Box display="flex" flexDirection={props.flexDirection} justifyContent="space-around" flexWrap="wrap">
        {isEmpty(products) ?
          <CircularProgress sx={{alignItems: 'center'}}/> :
          products.map((product) => {
            return (
              <>
                <Box sx={{borderBottom: `1px solid ${colors.background}`}} mb={3}/>
                <Link to={generateLaptopComparisonUrl(props.currentProduct, product)} style={{textDecoration: 'none'}}>
                  <SimilarProduct product={product} />
                </Link>
              </>
            )
          })
        }
      </Box>
    </>
  )
}

SimilarProducts.defaultProps = {
  flexDirection: 'column'
}