import { Box, Button, Typography as Text, Typography, } from '@mui/material'
import { useEffect, useState } from 'react'
import { Laptop } from 'types/laptop'
import Grid from '@mui/material/Unstable_Grid2'
import { colors} from 'theme'
import TopLaptopsService from 'services/laptops/TopLaptopsService'
import LaptopCard from 'components/Laptops/LaptopCard';
import PageLayout, { SeoMetaTags } from 'components/Laptops/PageLayout'
import { Link } from 'react-router-dom'

const backgroundHeroImage = require(`images/LaptopHomeHeader.jpg`)

const seoMetaTags: SeoMetaTags = {
  title: 'SpecRanks | Find the best laptop for you',
  description: 'Find the best laptop for you using our dynamic ranking system. Compare laptops side by side.'
}

const heroSection = (
  <Box display="flex" height={500} alignItems="center" justifyContent="center" flexDirection="column" mb={6}
  sx={{
    background: `url(${backgroundHeroImage}) 0% 0% / cover no-repeat fixed rgba(28, 80, 157, 0.4)`,
    backgroundBlendMode: 'multiply'
  }}>
    <Typography color="white" variant="h1" fontSize={32} fontWeight={500}>Find the perfect laptop for you.</Typography>
    <Typography color="white" mt={1}>We'll help you pick the right one.</Typography>
    <Link to="/laptops/quiz" style={{textDecoration: 'none'}}>
      <Button
        sx={{
          background: 'white',
          borderRadius: 8,
          px: 6,
          py: 1.5,
          mt: 4
        }}
      >
        <Typography fontWeight={600} color="#1476c1">Start Here</Typography>
      </Button>
    </Link>
  </Box>
)

export default function LaptopHome() {
  const [overallLaptops, setOverallLaptops] = useState<Laptop[]>([])
  const [performanceLaptops, setPerformanceLaptops] = useState<Laptop[]>([])
  const [portabilityLaptops, setPortabilityLaptops] = useState<Laptop[]>([])

  useEffect(() => {
    fetchOverallLaptops()
    fetchPerformanceLaptops()
    fetchPortabilityLaptops()
  }, [])

  const fetchOverallLaptops = () => {
    TopLaptopsService.overallLaptops()
    .then((data) => {setOverallLaptops(data)})
    .catch((err) => {console.log(err)})
  }

  const fetchPerformanceLaptops = () => {
    TopLaptopsService.performanceLaptops()
    .then((data) => {setPerformanceLaptops(data)})
    .catch((err) => {console.log(err)})
  }

  const fetchPortabilityLaptops = () => {
    TopLaptopsService.portabilityLaptops()
    .then((data) => {setPortabilityLaptops(data)})
    .catch((err) => {console.log(err)})
  }

  return (
    <PageLayout heroSection={heroSection} seoMetaTags={seoMetaTags}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" mt={8} mb={2}>
            <Text variant="h3" fontWeight="700" color={colors.green40}>Best Overall Laptops</Text>
          </Box>
        </Grid>
        {overallLaptops.map((laptop) => (
          <Grid xs={12} sm={6} md={4} >
            <LaptopCard laptop={laptop} showBars />
          </Grid>
        ))}
        <Grid xs={12}>
          <Link to="/laptops/search?sort=overall" style={{textDecoration: 'none'}}>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #1e5c19 10%, #26b21a 90%)',
                borderRadius: 8,
                px: 8,
                py: 1.5,
                mt: 4
              }}
            >
              <Typography color="white" fontWeight={600}>View More</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" mt={16} mb={2}>
            <Text variant="h3" fontWeight="700" color={colors.red}>Best Performance Laptops</Text>
          </Box>
        </Grid>
        {performanceLaptops.map((laptop) => (
          <Grid xs={12} sm={6} md={4} >
            <LaptopCard laptop={laptop} showBars />
          </Grid>
        ))}
        <Grid xs={12}>
          <Link to="/laptops/search?sort=performance" style={{textDecoration: 'none'}}>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #912f2f 10%, #eb4e4e 90%)',
                borderRadius: 8,
                px: 8,
                py: 1.5,
                mt: 4
              }}
            >
              <Typography color="white" fontWeight={600}>View More</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" mt={16} mb={2}>
            <Text variant="h3" fontWeight="700" color={colors.blue60}>Best Portability Laptops</Text>
          </Box>
        </Grid>
        {portabilityLaptops.map((laptop) => (
          <Grid xs={12} sm={6} md={4}>
            <LaptopCard laptop={laptop} showBars />
          </Grid>
        ))}
        <Grid xs={12}>
          <Link to="/laptops/search?sort=portability" style={{textDecoration: 'none'}}>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90%)',
                borderRadius: 8,
                px: 8,
                py: 1.5,
                mt: 4
              }}
            >
              <Typography color="white" fontWeight={600}>View More</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

