import { Box, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { compact } from "lodash"
import {colors } from "theme"
import { Laptop } from "types/laptop"

type ProductSpecificationProps = {
  product: Laptop
}

const batterySpecification = (product: Laptop) => {
  if(!product.battery) return null
  return {
    title: 'Battery Life',
    details: `${product.battery} hours`
  }
}

export default function ProductSpecifications(props: ProductSpecificationProps) {
  const specificationCategories = compact([
    {
      title: 'Display Size',
      details: `${props.product.displaySize}"`
    },
    {
      title: 'Display Resolution',
      details: `${props.product.displayWidth} x ${props.product.displayHeight}`
    },
    {
      title: 'Processor',
      details: props.product.cpuName
    },
    {
      title: 'Graphics',
      details: `${props.product.gpuBrand} ${props.product.gpuName}`
    },
    {
      title: 'Memory',
      details: `${props.product.ramSize}GB RAM`
    },
    {
      title: 'Storage',
      details: props.product.harddriveText
    },
    batterySpecification(props.product),
    {
      title: 'Weight',
      details: `${props.product.weight} lbs.`
    },
    {
      title: 'Dimensions (W x D x H)',
      details: `${props.product.width}" x ${props.product.length} x ${props.product.height}`
    },
    {
      title: 'Operating System',
      details: props.product.os
    }
  ])

  return (
    <>
      <Typography fontSize={30} fontWeight={200}>Specifications</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      {specificationCategories.map((category, i) => {
        return (
          <Box key={i} sx={{backgroundColor: i % 2 === 0 ? colors.background : null}} width="100%" m={0}>
            <Grid key={i} container py={1} >
              <Grid xs={6}><Typography textAlign="left">{category.title}</Typography></Grid>
              <Grid xs={6}><Typography textAlign="left">{category.details}</Typography></Grid>
            </Grid>
          </Box>
        )
      })}
    </>
  )
}