import { Typography } from "@mui/material"
import { Laptop } from "types/laptop"

export const ResultReasons: Function = (laptop: Laptop): React.ReactElement[] => {
  const reasons: React.ReactElement[] = []

  if(parseFloat(laptop.cpuScore) > 8.0) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        Equipped with the <strong>{laptop.cpuBrand} {laptop.cpuModel}</strong>, this laptop's processing speed is one of the best in the market.
      </Typography>
    )
  }

  if(parseFloat(laptop.gpuScore) > 8.0) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        Equipped with the <strong>{laptop.gpuBrand} {laptop.gpuModel}</strong>, this laptop's graphics capabilities are perfect for gaming and professional workloads.
      </Typography>
    )
  }

  if(parseFloat(laptop.weightScore) > 6.0) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        With a weight of <strong>{laptop.weight} lbs</strong>, this laptop is light enough to carry around with ease.
      </Typography>
    )
  }

  if(parseFloat(laptop.thicknessScore) > 7.0) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        With a thickness of <strong>{laptop.thickness} inches</strong>, this laptop is thin and can easily fit in a bag.
      </Typography>
    )
  }

  if(parseFloat(laptop.displayScore) > 8) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        This laptop's <strong>{laptop.displaySize} inch, {laptop.displayWidth} x {laptop.displayHeight} resolution</strong> screen is one of the sharpest in the market.
      </Typography>
    )
  }

  if(parseFloat(laptop.batteryScore) > 7) {
    reasons.push(
      <Typography textAlign="left" fontSize={18} mb={3} color="white">
        With <strong>{laptop.battery} hours</strong> of estimated use per charge, battery life is one of the best in the market.
      </Typography>
    )
  }

  reasons.push(
    <Typography textAlign="left" fontSize={18} mb={3} color="white">
      This laptop's <strong>{laptop.displaySize} inch</strong> display fits your size requirements.
    </Typography>
  )

  return reasons
}