import { SxProps, Typography } from "@mui/material"

type PriceTextProps = {
  price: number | string
  sx?: SxProps
}

export default function PriceText(props: PriceTextProps) {
  const priceString = Number(props.price).toFixed(2)

  return (
    <Typography fontSize={20} lineHeight={1} sx={{...props.sx, verticalAlign: 'top'}}>
      $<strong>{priceString.split('.')[0]}</strong><span style={{fontSize: 14, verticalAlign: 'top'}}>.{priceString.split('.')[1]}</span>
    </Typography>
  )
}