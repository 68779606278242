import {
  Navigate
} from "react-router-dom";
import Laptops from 'views/laptops/index'
import LaptopProduct from 'views/laptops/Product'
import Comparison from 'views/laptops/Comparison';
import Compare from "views/laptops/Compare";
import Search from "views/laptops/Search";
import Finder from "views/laptops/Finder";
import Quiz from "views/laptops/Quiz";
import QuizResults from "views/laptops/QuizResults";
import Disclosure from "views/laptops/Disclosure";
import About from "views/laptops/About";

type LaptopRoute = {
  path: string
  element: React.ReactElement
}

export const LaptopRoutes: LaptopRoute[] = [
  {
    path: "/",
    element: <Navigate to="/laptops"/>,
  },
  {
    path: '/laptops',
    element: <Laptops/>
  },
  {
    path: '/laptops/*',
    element: <LaptopProduct/>
  },
  {
    path: '/laptops/comparison/*',
    element: <Comparison/>
  },
  {
    path: '/laptops/compare',
    element: <Compare/>
  },
  {
    path: '/laptops/search',
    element: <Search/>
  },
  {
    path: '/laptops/finder',
    element: <Finder/>
  },
  {
    path: '/laptops/quiz/',
    element: <Quiz/>
  },
  {
    path: '/laptops/quiz/results',
    element: <QuizResults/>
  },
  {
    path: '/laptops/disclosure',
    element: <Disclosure/>
  },
  {
    path: '/laptops/about',
    element: <About/>
  }
]