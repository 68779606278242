import {memo} from 'react'
import { Box, Typography } from "@mui/material"
import Card from "components/Card"
import PriceText from "components/PriceText"
import { colors } from "theme"
import { Laptop } from "types/laptop"
import { generateLaptopImageUrl, generateLaptopProductUrl } from "utils/laptop"

type LaptopCardProps = {
  laptop: Laptop
  scoreType: 'overallScore' | 'performanceScore' | 'portabilityScore'
  showBars?: boolean
}

const LaptopCard = (props: LaptopCardProps): React.ReactElement => {
  const productUrl = generateLaptopProductUrl(props.laptop)
  const productImage = generateLaptopImageUrl(props.laptop)

  const scoreProperties = {
    overallScore: {
      color: colors.green40,
      text: 'Overall'
    },
    performanceScore: {
      color: colors.red,
      text: 'Performance'
    },
    portabilityScore: {
      color: colors.blue50,
      text: 'Portability'
    }
  }

  const performancePercent = (Number(props.laptop.performanceScore) * 10).toFixed(0).toString() + "%"
  const portabilityPercent = (Number(props.laptop.portabilityScore) * 10).toFixed(0).toString() + "%"

  return (
    <Card mt={1} link={productUrl}>
      <img src={productImage} alt={`${props.laptop.brand} ${props.laptop.title}`} style={{maxHeight: 140, objectFit: 'contain', marginTop: 16}} />
      <Typography noWrap textOverflow="ellipsis" overflow="hidden" fontWeight={300} mt={1} mb={1} textAlign="left">
          {props.laptop.brand} {props.laptop.title}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="start" maxWidth='100%' mt={1}>
        <Box display="flex" flexDirection="column" flexShrink={0} justifyContent="center" alignItems="center" sx={{
          background: scoreProperties[props.scoreType].color,
          height: 60,
          width: 60,
          borderRadius: 1,
          color: 'white'}}>
          <Typography fontSize={24} fontWeight={500} lineHeight={1} color="white">{props.laptop[props.scoreType]}</Typography>
          <Typography fontSize={10} fontWeight={400} color="white">{scoreProperties[props.scoreType].text}</Typography>
        </Box>
        {props.showBars ? <Box display="flex" flexGrow={1} flexDirection="row" justifyContent="center" ml={2}>
            <Box flexGrow={1}>
              <Box flexGrow={1} height={8} sx={{backgroundColor: '#d5d5d5'}} mt={1}>
                <Box width={performancePercent} height={8} sx={{backgroundColor: scoreProperties['performanceScore'].color}}></Box>
              </Box>
              <Box flexGrow={1} height={8} sx={{backgroundColor: '#d5d5d5'}} mt={2}>
                <Box width={portabilityPercent} height={8} sx={{backgroundColor: scoreProperties['portabilityScore'].color}}></Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" ml={2}>
              <Typography color={scoreProperties['performanceScore'].color} fontWeight={500} fontSize={14}>Performance</Typography>
              <Typography color={scoreProperties['portabilityScore'].color} fontWeight={500} fontSize={14}>Portability</Typography>
            </Box>
          </Box> : 
          <Box>
            <Box display="flex" flexDirection="row" ml={1.3}>
              <Typography fontWeight={600} color={scoreProperties['performanceScore'].color} mr={1}>{props.laptop.performanceScore}</Typography>
              <Typography>Performance</Typography>
            </Box>
            <Box display="flex" flexDirection="row" ml={1.3}>
              <Typography fontWeight={600} color={scoreProperties['portabilityScore'].color} mr={1}>{props.laptop.portabilityScore}</Typography>
              <Typography>Portability</Typography>
            </Box>
          </Box>
          }
      </Box>
      <Box mt={2.5} display="flex" alignItems="start">
        <PriceText price={props.laptop.listprice}/>
      </Box>
    </Card>
  )
}

LaptopCard.defaultProps = {
  scoreType: 'overallScore'
}

export default memo(LaptopCard)