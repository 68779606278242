import ky from 'ky'
import { Laptop } from 'types/laptop'

export default class TopLaptopsService {
  static overallLaptops = async (limit: number = 3): Promise<Laptop[]> => {
    return await ky.get(`${process.env.REACT_APP_API_URL}/api/laptoplist/overall_score/${limit}`).json<Laptop[]>()
  }

  static performanceLaptops = async (limit: number = 3): Promise<Laptop[]> => {
    return await ky.get(`${process.env.REACT_APP_API_URL}/api/laptoplist/performance_score/${limit}`).json<Laptop[]>()
  }

  static portabilityLaptops = async (limit: number = 3): Promise<Laptop[]> => {
    return await ky.get(`${process.env.REACT_APP_API_URL}/api/laptoplist/portability_score/${limit}`).json<Laptop[]>()
  }
}