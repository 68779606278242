import { AppBar, Box, Button, Container, Typography } from "@mui/material"
import { colors } from "theme"

type QuizFooterProps = {
  page: number
  setPage: (value: number) => void
  nextEnabled: boolean
  resultsCount?: number
}

const QuizFooter = (props: QuizFooterProps) => {

  const nextDisabled = !props.nextEnabled || (props.page === 3 && props.resultsCount === 0)
  return (
    <AppBar position="fixed" sx={{top: 'auto', bottom: 0, borderTop: '1px solid #3770c329'}} >
      <Container>
        <Box display="flex" justifyContent="space-between" py={2}>
          {props.page !== 0 && <Button
            onClick={() => props.setPage(props.page - 1)}
            sx={{
              border: `1px solid ${colors.blue30}`,
              borderRadius: 8,
            }}
          >
            <Typography color={colors.blue30} fontWeight={600} py={0.5} px={2}>Back</Typography>
          </Button>}
          <Box>
            status
          </Box>
          <Box>
          <Button
            onClick={() => props.setPage(props.page + 1)}
            disabled={nextDisabled}
            sx={{
              border: `1px solid white`,
              background: !nextDisabled ? 'linear-gradient(45deg, #14458C 10%, #1D64CE 90%)' : '#ddd',
              borderRadius: 8,
              "& .MuiTouchRipple-child": {
                backgroundColor: `white !important`,
              },
            }}
          >
            <Typography color="white" fontWeight={600} py={0.5} px={2}>Next</Typography>
          </Button>
          </Box>
        </Box>
      </Container>
    </AppBar>
  )
}

export default QuizFooter