import { Public as GlobeIcon, Description, Theaters, Photo, VideoCameraBack, Gamepad, ViewInAr,
  Desk, Flight, Backpack } from '@mui/icons-material'
import { useCallback, useEffect, useState } from "react";
import QuizQuestion from "components/Laptops/QuizQuestion";
import QuizFooter from "components/Laptops/QuizFooter";
import { debounce, isEmpty } from "lodash";
import ky from "ky";
import {mapSelectionsToRequestParams } from "utils/laptopQuiz";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";

const seoMetaTags = { 
  title: 'Laptop Quiz | Find The Perfect Laptop For You',
  description: 'Take the laptop quiz to get personalized laptop recommendations.'
}

type AnswerOption = {
  title: string
  subTitle: string
  icon?: React.ReactElement
  value: string
}

export type Question = {
  title: string
  subTitle: string
  answers: AnswerOption[]
  input?: boolean
  singleSelection?: boolean
  keyName: string
}

export default function Quiz() {
  const [page, setPage] = useState<number>(0)
  const [selections, setSelections] = useState<{[key: string]: string[]}>({})
  const [resultsCount, setResultsCount] = useState<number>()
  const navigate = useNavigate()

  useEffect(() => {
    onSelection(selections)
  }, [selections])

  const onSelection = useCallback(debounce((currentSelections) => {
    ky.post(`${process.env.REACT_APP_API_URL}/api/laptop-finder-count`, {
      json: mapSelectionsToRequestParams(currentSelections)
    }).json<{count: string}>()
    .then((data) => {
      setResultsCount(parseInt(data.count))
    })
    .catch((err) => {
      console.log(err)
    })
  }, 300), [])

  const handleSetPage = (value: number): void => {
    page >= 3 ?
      navigate('/laptops/quiz/results', {state: {data: mapSelectionsToRequestParams(selections)}}) :
      setPage(value)
  }

  const isNextEnabled = () => {
    return !isEmpty(selections[questions[page].keyName]) 
  }

  return (
    <PageLayout seoMetaTags={seoMetaTags} hideFooter>
      <Grid container display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        <Grid xs={12} sm={8} md={6}>
          <QuizQuestion {...questions[page]} selections={selections} setSelections={setSelections} resultsCount={resultsCount} />
        </Grid>
      </Grid>
      <QuizFooter page={page} setPage={handleSetPage} nextEnabled={isNextEnabled()} resultsCount={resultsCount} />
    </PageLayout>
  )
}

const questions: Question[] = [
  {
    title: 'What will you be using this laptop for?',
    subTitle: 'Select all that apply.',
    keyName: 'performance',
    answers: [
      {
        title: 'Internet Browsing',
        subTitle: 'Social media, email, YouTube, etc.',
        icon: <GlobeIcon sx={{fontSize: 42}} />,
        value: 'browsing'
      },
      {
        title: 'Documents',
        subTitle: 'Word, Excel, Powerpoint, etc.',
        icon: <Description sx={{fontSize: 42}} />,
        value: 'documents'
      },
      {
        title: 'Movies',
        subTitle: 'Netflix, HBO Max, Hulu, etc.',
        icon: <Theaters sx={{fontSize: 42}} />,
        value: 'movies'
      },
      {
        title: 'Photography and Design',
        subTitle: 'Photo editing, graphic design',
        icon: <Photo sx={{fontSize: 42}} />,
        value: 'photography'
      },
      {
        title: 'Video Production',
        subTitle: 'Videography and film making',
        icon: <VideoCameraBack sx={{fontSize: 42}} />,
        value: 'video'
      },
      {
        title: 'Heavy Gaming',
        subTitle: 'Graphically intensive games',
        icon: <Gamepad sx={{fontSize: 42}} />,
        value: 'gaming'
      },
      {
        title: '3D Modeling',
        subTitle: 'Blender, Solidworks, etc. ',
        icon: <ViewInAr sx={{fontSize: 42}} />,
        value: 'modeling'
      },
    ]
  },
  {
    title: 'How often will you carry your laptop around with you?',
    subTitle: 'Select one.',
    singleSelection: true,
    keyName: 'portability',
    answers: [
      {
        title: 'Never',
        subTitle: 'Stationary on desk',
        icon: <Desk sx={{fontSize: 42}} />,
        value: 'never'
      },
      {
        title: 'Occasionally',
        subTitle: 'Students, coffee shops, etc.',
        icon: <Backpack sx={{fontSize: 42}} />,
        value: 'occasionally'
      },
      {
        title: 'Often',
        subTitle: 'Travelling professionals',
        icon: <Flight sx={{fontSize: 42}} />,
        value: 'often'
      }
    ]
  },
  {
    title: 'Do you have a screen size preference?',
    subTitle: 'Select all that apply.',
    keyName: 'size',
    singleSelection: true,
    answers: [
      {
        title: 'No Preference',
        subTitle: 'Other specs matter more than size.',
        value: 'none'
      },
      {
        title: 'Less than 12"',
        subTitle: 'Ultra portable, small keyboard',
        value: 'xs'
      },
      {
        title: '12" - 13.9"',
        subTitle: 'Lightweight, comfortable',
        value: 'sm'
      },
      {
        title: '14" - 15.6"',
        subTitle: 'Full size keyboard, larger screen',
        value: 'md'
      },
      {
        title: 'More than 15.6"',
        subTitle: 'Heavy, large',
        value: 'lg'
      },
    ]
  },
  {
    title: "What is the most you're willing to spend?",
    subTitle: "We'll look for the absolute best, up to this price.",
    keyName: 'price',
    singleSelection: true,
    answers: [],
    input: true
  }
]
