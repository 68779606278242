import { Box, Typography} from "@mui/material";
import { Laptop } from "types/laptop";
import {colors} from 'theme'
import RatingCategory, {RatingCategoryProps} from "components/RatingCategory";
import { compact, isNil } from "lodash";

type ProductBreakdownProps = {
  product: Laptop;
}

const batteryCategory = (product: Laptop) => {
  if(isNil(product.battery)) return null
  return {
    title: 'Battery',
    subTitle: 'based on manufacturer estimate',
    barText: `${product.battery} hours`,
    score: product.batteryScore
  }
}

export default function ProductBreakdown(props: ProductBreakdownProps) {
  const breakdownCategories = compact([
    {
      title: 'Display Sharpness',
      subTitle: 'based on pixel density',
      barText: `${props.product.displayDensity} PPI`,
      score: props.product.displayScore,
    },
    {
      title: 'Processor Speed',
      subTitle: 'based on benchmarks',
      barText: props.product.cpuName,
      score: props.product.cpuScore
    },
    {
      title: 'Graphics',
      subTitle: 'based on benchmarks',
      barText: `${props.product.gpuBrand} ${props.product.gpuName}`,
      score: props.product.gpuScore
    },
    {
      title: 'Memory',
      subTitle: 'based on memory size',
      barText: `${props.product.ramSize} GB`,
      score: props.product.ramScore
    },
    {
      title: 'Storage Class',
      subTitle: 'based on drive type',
      barText: props.product.harddriveType,
      score: props.product.harddriveScore
    },
    batteryCategory(props.product),
    {
      title: 'Weight',
      subTitle: 'based on manufacturer\'s listing',
      barText: `${props.product.weight} lbs.`,
      score: props.product.weightScore
    },
    {
      title: 'Slimness',
      subTitle: 'based on manufacturer\'s listing',
      barText: `${props.product.thickness} in.`,
      score: props.product.thicknessScore
    },
    {
      title: 'Overall Size',
      subTitle: 'based on manufacturer\'s listing',
      barText: <>{props.product.size} in <sup>3</sup></>,
      score: props.product.sizeScore
    }
  ]) as RatingCategoryProps[];

  return (
    <Box mb={2}>
      <Typography fontSize={30} fontWeight={300}>Laptop Breakdown</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      {breakdownCategories.map((category, i) => {
        return <RatingCategory key={i} {...category}/>
      })}
    </Box>
  )
}

