import {Box, Typography} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Box display="flex" flexDirection="column" height={200} mt={10} alignItems="center" justifyContent="center"  sx={{backgroundColor: '#202023'}}>
      <Grid container sx={{color: 'white'}} spacing={3}>
        <Grid><Link to="/laptops/about" style={{textDecoration: 'none', color: 'white'}}>About</Link></Grid>
        <Grid><Link to="/laptops/disclosure" style={{textDecoration: 'none', color: 'white'}}>Disclosure</Link></Grid>
        {/* <Grid>Sitemap</Grid> */}
      </Grid>
      <Typography color="#686D70" fontSize={14} mt={2}>Copyright {(new Date()).getFullYear()} ©. All rights reserved.</Typography>
    </Box>
  )
}