import { Box, Typography } from "@mui/material";
import RatingBar from 'components/RatingBar'
import Grid from '@mui/material/Unstable_Grid2'

export type RatingCategoryProps = {
  title?: string;
  subTitle?: string;
  barText?: string | React.ReactElement;
  secondBarText?: string | React.ReactElement;
  score: string;
  secondScore?: string;
  barColor?: string;
  paddingTopBar?: number
  isComparison?: boolean
}

export default function RatingCategory(props: RatingCategoryProps) {
  const isComparison = props.isComparison || false

  const firstProductColor = Number(props.score) > Number(props.secondScore) ? props.barColor : '#96969e'
  const secondBarColor = Number(props.secondScore) > Number(props.score) ? props.barColor : '#96969e'

  return (
    <Box mt={2}>
      <Typography fontSize={24} fontWeight={200} mb={0}>{props.title}</Typography>
      <Typography fontSize={14} color="#aaaaaa" lineHeight={1} mb={1.7}>{props.subTitle}</Typography>
      <Grid container spacing={2} p={0} mt={props.paddingTopBar}>
        <Grid xs={isComparison ? 6 : 12} py={0}>
          <RatingBar score={props.score} barText={props.barText} color={isComparison ? firstProductColor : props.barColor} />
        </Grid>
        {isComparison && <Grid xs={6} py={0}>
          <RatingBar score={props.secondScore} barText={props.secondBarText} color={secondBarColor} reverse/>
        </Grid>}
      </Grid>
    </Box>
  )
}
