import { Box, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { compact, isNil } from "lodash"
import { colors } from "theme"
import { Laptop } from "types/laptop"

type ProductSpecificationProps = {
  firstProduct: Laptop
  secondProduct: Laptop
}

const batterySpecification = (firstProduct: Laptop, secondProduct: Laptop) => {
  if(isNil(firstProduct.battery) && isNil(secondProduct.battery)) return null

  return {
    firstDetails: firstProduct.battery && `${firstProduct.battery} hours`,
    secondDetails: secondProduct.battery && `${secondProduct.battery} hours`
  }
}

export default function ProductSpecifications(props: ProductSpecificationProps) {
  const specificationCategories = compact([
    {
      firstDetails: `${props.firstProduct.displaySize}"`,
      secondDetails: `${props.secondProduct.displaySize}"`
    },
    {
      firstDetails: `${props.firstProduct.displayWidth} x ${props.firstProduct.displayHeight}`,
      secondDetails: `${props.secondProduct.displayWidth} x ${props.secondProduct.displayHeight}`
    },
    {
      firstDetails: props.firstProduct.cpuName,
      secondDetails: props.secondProduct.cpuName
    },
    {
      firstDetails: `${props.firstProduct.gpuBrand} ${props.firstProduct.gpuName}`,
      secondDetails: `${props.secondProduct.gpuBrand} ${props.secondProduct.gpuName}`
    },
    {
      firstDetails: `${props.firstProduct.ramSize}GB RAM`,
      secondDetails: `${props.secondProduct.ramSize}GB RAM`
    },
    {
      firstDetails: props.firstProduct.harddriveText,
      secondDetails: props.secondProduct.harddriveText
    },
    batterySpecification(props.firstProduct, props.secondProduct),
    {
      firstDetails: `${props.firstProduct.weight} lbs.`,
      secondDetails: `${props.secondProduct.weight} lbs.`
    },
    {
      firstDetails: `${props.firstProduct.width}" x ${props.firstProduct.length} x ${props.firstProduct.height}`,
      secondDetails: `${props.secondProduct.width}" x ${props.secondProduct.length} x ${props.secondProduct.height}`
    },
    {
      firstDetails: props.firstProduct.os,
      secondDetails: props.secondProduct.os
    }
  ])
  return (
    <>
      <Typography fontSize={30} fontWeight={200}>Specifications</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      {specificationCategories.map((category, i) => {
        return (
          <Box key={i} sx={{backgroundColor: i % 2 === 0 ? colors.background : null}} width="100%" m={0}>
            <Grid key={i} container spacing={4} py={1} >
              <Grid xs={6}><Typography textAlign="right">{category.firstDetails}</Typography></Grid>
              <Grid xs={6}><Typography textAlign="left">{category.secondDetails}</Typography></Grid>
            </Grid>
          </Box>
        )
      })}
    </>
  )
}