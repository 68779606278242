import { useState, useRef, useCallback } from "react";
import { Box, Button, CircularProgress, Drawer, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "components/Card";
import LaptopCard from "components/Laptops/LaptopCard";
import ky from "ky";
import _, { debounce, isUndefined } from "lodash"
import { Laptop } from "types/laptop";
import { Formik, Form, FormikValues } from "formik";
import { useInfiniteQuery } from "@tanstack/react-query";
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout";
import RefineSearch from "components/Laptops/RefineSearch";
import SortByFilter, { sortMap } from "components/Laptops/SortByFilter";
import { Tune, Cancel } from "@mui/icons-material"
import LoadingPage from "components/LoadingPage";
import { useSearchParams } from "react-router-dom";

const seoMetaTags: SeoMetaTags = {
  title: 'Search Laptops | Find The Best Laptop For YOu',
  description: 'Find laptops with our intiutive ranking system. Find the cheapest laptops, the most powerful laptops, or the laptops with the best battery life.'
}

export type SearchQuery = {
  searchText: string
  priceRange: number[]
  sortBy: string
  screenSizeRange: number[]
  overallScoreRange: number[],
  performanceScoreRange: number[]
  portabilityScoreRange: number[]
  displayScoreRange: number[]
  cpuScoreRange: number[]
  gpuScoreRange: number[]
  ramScoreRange: number[]
  harddriveScoreRange: number[]
  weightScoreRange: number[]
  thicknessScoreRange: number[]
  sizeScoreRange: number[]
  batteryScoreRange: number[]
}

const initialValues: SearchQuery = {
  searchText: '',
  sortBy: 'Best Overall',
  priceRange: [0, 3000],
  screenSizeRange: [0, 19],
  overallScoreRange: [0, 10],
  performanceScoreRange: [0, 10],
  portabilityScoreRange: [0, 10],
  displayScoreRange: [0, 10],
  cpuScoreRange: [0, 10],
  gpuScoreRange: [0, 10],
  ramScoreRange: [0, 10],
  harddriveScoreRange: [0, 10],
  weightScoreRange: [0, 10],
  thicknessScoreRange: [0, 10],
  sizeScoreRange: [0, 10],
  batteryScoreRange: [0, 10],
}

type FetchResults = {
  data: Laptop[],
  nextPage: number,
  totalCount: number
}

export default function Search() {
  const [totalResults, setTotalResults] = useState<number>(0)
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const sortQueryParam = () => sortMap[searchParams.get('sort') || "overall"]

  const fetchSearchResults = async (params: any) => {
    const results = await ky.post(`${process.env.REACT_APP_API_URL}/api/searchLaptops`, {
      json: {
        ...formRef.current.values,
        limit: 15,
      },
      searchParams: {
        page: params.pageParam
      }
    }).json<FetchResults>()

    setTotalResults(results.totalCount)

    return results
  }
  
  const {
    data,
    isFetching,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['laptopSearchResults'],
    queryFn: fetchSearchResults,
    getNextPageParam: (lastPage, _) => lastPage.nextPage
  })

  const formRef = useRef<any>({})
  const observer = useRef<IntersectionObserver>()
  const lastElementRef = useCallback((element: any) => {
    if(isLoading) return 
    if(observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(elements => {
      if(elements[0].isIntersecting) {
        fetchNextPage()
      }
    })

    if(element){ observer.current.observe(element)}
  }, [isLoading, fetchNextPage])

  if(isLoading || isUndefined(data)) {
    return <LoadingPage/>
  }

  const toggleFiltersDrawer = () => setIsFiltersDrawerOpen(!isFiltersDrawerOpen)

  const debounceSubmit = debounce((values) => {
    refetch(values)
  }, 500)

  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Formik
        innerRef={formRef}
        initialValues={{...initialValues, sortBy: sortQueryParam()}}
        onSubmit={debounceSubmit}
      >{(formik: FormikValues) => 
      {
        return (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid xs={12} md={3} display={{xs: 'none', md: 'block'}}>
                  <Card>
                    <RefineSearch />
                  </Card>
                </Grid>
                <Grid xs={12} md={9}>
                  <Card sx={{paddingBottom: 1.5}}>
                    <TextField 
                      size="small" 
                      placeholder="Search"
                      onChange={(e) => {formik.setFieldValue('searchText', e.target.value); formik.handleSubmit()}}
                    />
                    <Box mt={1.5} display="flex" justifyContent="space-between" alignItems="center">
                      <Typography textAlign="left" fontSize={14}>{totalResults} results</Typography>
                      {
                        isMobile ?
                        <Button onClick={toggleFiltersDrawer}>
                          Refine Search &nbsp; <Tune sx={{fontSize: 16}}/>
                        </Button> : 
                        <SortByFilter/>
                      }
                    </Box>
                  </Card>
                  {
                    isFetching && !isFetchingNextPage ? 
                      <CircularProgress sx={{alignItems: 'center', mt: 4}}/> :
                      <Results searchResults={data.pages} lastElementRef={lastElementRef} loading={isFetching} />
                  }
                </Grid>
              </Grid>
            </Form>
            <Drawer
              anchor="bottom"
              open={isFiltersDrawerOpen}
              onClose={toggleFiltersDrawer}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'flex', md: 'none' },
                '& .MuiDrawer-paper': {  width: '100vw' },
              }}
            >
             <Box position="absolute" top={0} right={0}>
              <Button onClick={toggleFiltersDrawer}>
                <Cancel/>
              </Button>
            </Box>
            <Box px={4} pt={4}>
              <RefineSearch/>
            </Box>
            <Box 
              display="flex"
              position="fixed"
              bottom={0}
              width="100%"
              height={100}
              justifyContent="space-between"
              alignItems="center"
              sx={{backgroundColor: 'white', borderTop: '1px solid #ededf3' }}
              px={4}
            >
              <Button
                sx={{
                  border: '1px solid #14458C',
                  borderRadius: 8,
                  marginRight: 1,
                  flexGrow: 1,
                  paddingY: 1.5
                }}
                onClick={formik.resetForm}
              >
                <Typography color="#14458C">Clear</Typography>
              </Button>
              <Button 
                sx={{
                  background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90% )',
                  borderRadius: 8,
                  marginLeft: 1,
                  flexGrow: 1,
                  paddingY: 1.5
                }}
                onClick={toggleFiltersDrawer}
              >
                <Typography color="white">Apply</Typography>
              </Button>
            </Box>
          </Drawer>
        </>
        )}
      }
      </Formik>
    </PageLayout>
  )
}

type ResultsProps = {
  searchResults: FetchResults[]
  loading: boolean
  lastElementRef: (element: any) => void
}
// Todo?: move outer map into main component to avoid rerendering
const Results = (props: ResultsProps) => {
  if(props.searchResults[0].data.length === 0) { 
    return <Box mt={4}>No results.</Box>
  }

  return (
    <Grid container spacing={2} px={0}>
      {
        props.searchResults.map((page, i) => {
          return page.data.map((laptop, j) => {
            if(props.searchResults.length === i + 1 && page.data.length === j + 1) {
              return <Grid key={`${i}-${j}`} ref={props.lastElementRef} xs={12} sm={6} lg={4}><LaptopCard laptop={laptop} /></Grid>
            } else {
              return <Grid key={`${i}-${j}`} xs={12} sm={6} lg={4}><LaptopCard laptop={laptop} /></Grid>
            }
          })
        })
      }
    </Grid>
  )
}