import { motion} from 'framer-motion'
import { Box, CircularProgress, Link, Typography } from '@mui/material'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoadingPage() {
  const [errorMessage, setErrorMessage] = useState<JSX.Element>();
  const navigate = useNavigate()

  setTimeout(() => {
    setErrorMessage(
      <>
        <Typography>Loading is taking longer than usual.<br/><Link onClick={() => navigate(-1)}>Click here to go back.</Link></Typography>
      </>
    )
  }, 10000)

  return (
    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} >
      <Box display="flex" height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress/>
        {errorMessage}
      </Box>
    </motion.div>
  )
}