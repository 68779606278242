import { VictoryArea, VictoryAxis, VictoryChart, VictoryGroup, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import {parse as parseDate, lightFormat} from 'date-fns'
import { colors } from "theme";
import { Box, Typography } from "@mui/material";
import { isNil } from "lodash";

type PriceHistoryProps = {
  priceList: {
    [key: string]: string;
  },
  secondPriceList?: {
    [key: string]: string
  }
}

const formatPriceList = (priceList: {[key: string]: string}) => {
  return Object.entries(priceList).map((price) => {
    return {
      x: parseDate(price[0], "yyyyMMdd'T'hh:mm:ssSSS'Z'", new Date()),
      y: Number(price[1])
    }
  })
}

export default function PriceHistory(props: PriceHistoryProps) {
  if(isNil(props.priceList) && isNil(props.secondPriceList)) return null

  return (
    <>
      <Typography fontSize={30} fontWeight={200}>Price History</Typography>
      <Box sx={{borderBottom: `1px solid ${colors.background}`}} mt={2}/>
      <VictoryChart width={650} height={250} scale={{x: 'time'}}
        containerComponent={<VictoryVoronoiContainer/>}
      >
        {!isNil(props.priceList) && 
          <VictoryGroup
            style={{data: { strokeWidth: 2, fillOpacity: 0.4}}}
            labels={({ datum }) => { return `${lightFormat(datum.x, 'MM-dd-yyyy')} \n $${datum.y.toFixed(2)}`}}
            labelComponent={<VictoryTooltip style={{ fontSize: 10}}/>}
          >
            <VictoryArea
              style={{data: { fill: colors.blue50, stroke: colors.blue50 }}}
              data={formatPriceList(props.priceList)}
            />
          </VictoryGroup>
        }
        <VictoryAxis dependentAxis
          tickFormat={(x) => `$${x}`}
          style={{tickLabels: {fontFamily: 'Open Sans', fontSize: 10}}}
        />
        <VictoryAxis crossAxis
          style={{tickLabels: {fontFamily: 'Open Sans', fontSize: 10}}}
        />

        {!isNil(props.secondPriceList) &&
          <VictoryGroup
            style={{data: { strokeWidth: 2, fillOpacity: 0.4}}}
            labels={({ datum }) => `${lightFormat(datum.x, 'MM-dd-yyyy')} \n $${datum.y.toFixed(2)}`}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: 10 }}
              />
            }
          >
            <VictoryArea
              style={{data: { fill: colors.red, stroke: colors.red }}}
              data={formatPriceList(props.secondPriceList)}
            />
          </VictoryGroup>
        }
      </VictoryChart>
    </>
  );
}


