import { Typography, CircularProgress, Box, Button } from "@mui/material"
import Card from "components/Card"
import ScoreSummaries from "components/Laptops/ScoreSummaries"
import ky from "ky"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { colors } from "theme"
import { Laptop } from "types/laptop"
import Grid from '@mui/material/Unstable_Grid2'
import {generateLaptopName, generateLaptopImageUrl} from 'utils/laptop'
import { RatingCategoryProps } from "components/RatingCategory"
import RatingBar from "components/RatingBar"
import { ResultReasons } from "components/Laptops/ResultReasons"
import LaptopCard from "components/Laptops/LaptopCard"
import PageLayout, { SeoMetaTags } from "components/Laptops/PageLayout"
import LoadingPage from "components/LoadingPage"

const seoMetaTags: SeoMetaTags = {
  title: `Laptop Quiz Results | Find The Best Laptop For You`,
  description: `See customized laptop results for you.`
}

export default function QuizResults() { 
  const [laptops, setLaptops] = useState<Laptop[]>([])
  const location = useLocation()

  useEffect(() => {
    getLaptop()
  }, [])

  const getLaptop = () => {
    ky.post(`${process.env.REACT_APP_API_URL}/api/laptop-finder-results`, {
      json: location.state.data
    }).json<Laptop[]>()
    .then((data) => {
      setLaptops(data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  if(isEmpty(laptops)) {
    return <LoadingPage/>
  }

  const resultLaptop: Laptop = laptops[0]
  const suggestionLaptops: Laptop[] = laptops.slice(1)
  const reasons = ResultReasons(resultLaptop)

  return (
    <PageLayout seoMetaTags={seoMetaTags}>
      <Typography fontSize={36} fontWeight="700" color={colors.blue60} mt={2} mb={1}>Our Recommendation</Typography>
      <Card mt={2}>
        <Typography fontSize={24} fontWeight={200} mb={2} noWrap>{resultLaptop.brand} {resultLaptop.title}</Typography>
        <Grid container mb={2}>
          <Grid xs={12} md={6}>
            <img alt={generateLaptopName(resultLaptop)} src={generateLaptopImageUrl(resultLaptop)} style={{maxHeight: 360, maxWidth: '100%'}} />
          </Grid>
          <Grid xs={12} md={6} display="flex" alignItems='center' justifyContent="center" flexDirection='column'>
            <ScoreSummaries product={resultLaptop} mb={2} />
            <ResultBreakdown product={resultLaptop} />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" my={2}>
          <Grid md={6}>
            <a target="_blank" rel="noopener noreferrer" href={resultLaptop.url}>
              <Button fullWidth sx={{
                background: 'linear-gradient(45deg, #14458C 10%, #1D64CE 90% )',
                color: 'white',
                borderRadius: 4,
              }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%" py={1} px={1}>
                  <Typography color="white" fontWeight={600}>${Number(resultLaptop.listprice).toFixed(2)}</Typography>
                  <img src={require('images/amazon-white.png')} alt="Purchase on Amazon" height={20}/>
                </Box>
              </Button>
            </a>
          </Grid>
        </Grid>
      </Card>
      {
        !isEmpty(reasons) &&
        <Card mt={2} sx={{backgroundColor: colors.blue60}}>
          <Typography fontSize={32} fontWeight={700} color='white' mb={2} noWrap>Why we recommend this laptop</Typography>
          <Grid container justifyContent='center'>
            <Grid md={8}>
              <ul style={{color: 'white'}}>
                {reasons.map((reason: React.ReactElement, i: number) => {
                  return (
                    <li>
                      {reason}
                    </li>
                  )
                })}
              </ul>
            </Grid>
          </Grid>
        </Card>
      }
      <Typography fontSize={36} fontWeight="700" color={colors.blue60} mt={4} mb={1}>More laptops to consider</Typography>
      <Grid container spacing={2} mt={2} justifyContent="center">
        {suggestionLaptops.map((laptop, i) => (
          <Grid key={i} xs={12} sm={4}><LaptopCard laptop={laptop} showBars/></Grid>
        ))}
      </Grid>
    </PageLayout>
  )
}

const ResultBreakdown = (props: {product: Laptop}) => { 
  const breakdownCategories: RatingCategoryProps[] = [
    {title: 'Display sharpness', score: props.product.displayScore},
    {title: 'CPU speed', score: props.product.cpuScore},
    {title: 'Graphics', score: props.product.gpuScore},
    {title: 'Slimness', score: props.product.thicknessScore},
    {title: 'Battery life', score: props.product.batteryScore},
  ]

  return (
    <>
      {breakdownCategories.map((category, i) => (
        <Box key={i} width="100%" mt={1}>
          <Typography fontSize={14} mb={0} pb={0}>{category.title}</Typography>
          <RatingBar key={i} score={category.score} color={colors.blue60} mt={0} hideScore />
        </Box>)
      )}
    </>
  )
}
